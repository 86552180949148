//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

export const spotifyMonetizationService = {
	getSpotifyMonetization,
	getSpotifyMonetizationTotals,
	updateSpotifyMonetization
};

function getSpotifyMonetization(params){
	return config.live ? getSpotifyMonetizationLive(params) : getSpotifyMonetizationStatic();
}

function getSpotifyMonetizationLive(params) {
	const requestOptions = {
		method: 'GET',
		headers: AuthHeader()
	};
	
	let baseUrl = config.apis.v3_data+'/simple',
		qs = BuildQS(params.filtered);
		
	qs.query_name = 'spotify_monetization';
	qs.mode = 'table';
	
	qs.offset = params.page ? params.page*params.pageSize : 0;
	qs.row_limit = params.pageSize;
	
	let {sorted, filtered} = params;
	if(sorted !== undefined && sorted.length){
		sorted = sorted[0];
		qs.order_by = sorted.id;
		qs.order_dir = sorted.desc ? 'desc':'asc';
	}
	
	if(filtered !== undefined && filtered.length){
		for(let filter of filtered) {
			let value = filter.value;
			if(!value)
				continue;
			if(value instanceof Array) {
				value = value.join(',');
				if(!value)
					continue;
				qs[filter.id] = value;
			}
			else {
				qs[filter.id] = value;    
			}
		}            
	}

	
	qs.release_date = filtered.releaseDate;
	qs.months = filtered.months;
	qs.status = filtered.status;
	
	baseUrl += '?' + $.param(qs);

	return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSpotifyMonetizationStatic() {
	let baseUrl = `${config.apis.sales}/api/static/spotify_monetization.json`;
	return fetch(baseUrl).then(res=>res.json());
}

function getSpotifyMonetizationTotals(params){
	return config.live ? getSpotifyMonetizationTotalsLive(params) : getSpotifyMonetizationTotalsStatic();
}

function getSpotifyMonetizationTotalsLive(params) {
	const requestOptions = {
		method: 'GET',
		headers: AuthHeader()
	};
	
	let baseUrl = config.apis.v3_data+'/simple',
		qs = BuildQS(params.filtered);
		
	qs.query_name = 'spotify_monetization';
	qs.mode = 'main';
	
	let {filtered} = params;
	if(filtered !== undefined && filtered.length){
		for(let filter of filtered) {
			let value = filter.value;
			if(!value)
				continue;
			if(value instanceof Array) {
				value = value.join(',');
				if(!value)
					continue;
				qs[filter.id] = value;
			}
			else {
				qs[filter.id] = value;    
			}
		}            
	}

	
	qs.release_date = filtered.releaseDate;
	qs.months = filtered.months;
	qs.status = filtered.status;
	
	baseUrl += '?' + $.param(qs);

	return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSpotifyMonetizationTotalsStatic() {
	let baseUrl = `${config.apis.sales}/api/static/spotify_monetization_totals.json`;
	return fetch(baseUrl).then(res=>res.json());
}

function updateSpotifyMonetization(arr, cb) {
	const baseUrl = `${config.apis.miniCat}/v3/catalogue_exceptions/bulk_update`,

		requestOptions = {
			method: 'PATCH',
			headers: AuthHeader({'Content-Type': 'application/json'}),            
			body: JSON.stringify(arr)
		};        
	
	return fetch(baseUrl, requestOptions)
	.then(res=>{
		if (res.ok) {
			res.json()
			if (cb && typeof cb === 'function') {
				cb()
			}
		} else {
		  Promise.reject(res) 
		}
	})
	.catch(ex=>Promise.reject(ex))
}


