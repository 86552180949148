import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import { isEqual, maxBy, minBy } from 'lodash';

import CSV from '../../../helpers/CSVExport';
import { convertToThousands, splitByThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import PercentageValue from '../../common/PercentageValue';

import BarChart from '../../widgets/charts/types/BarChart';
import ScatterChart from '../../widgets/charts/types/ScatterChart';
import ChartLegend from '../../widgets/charts/ChartLegend';
import EntitySelector from '../../pages/result/EntitySelector';
import ModeSelect from '../../common/ModeSelect';

import { audienceActions } from '../../../data/actions/audience';
import { playlistSettings } from './Playlists/Settings';

const downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
const barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
const lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');


class RepeatListeners extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            view: props.defaultMode || 'bar',
            entityFilter: null
        }
        this.setChartView = this.setChartView.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
        this.setChartMode = this.setChartMode.bind(this);
    }

    componentDidMount(){
        const { view } = this.state;
        if(view == 'bar')
            this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter);
        else
            this.props.getRepeatListenersTimeseries(this.props.id, this.props.entity, this.state.entityFilter);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                const { view } = this.state;
                if(view == 'bar')
                    this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter);
                else
                    this.props.getRepeatListenersTimeseries(this.props.id, this.props.entity, this.state.entityFilter);        
            }
        }
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter));
    }
    
    setChartMode(view){
        this.setState({view});
        if(view == 'bar')
            this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter);
        else
            this.props.getRepeatListenersTimeseries(this.props.id, this.props.entity, this.state.entityFilter);
        
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(view);
    
    }

    
    exportToCsv(){
        const { view } = this.state;
        const datasetKey = view == 'bar' ? 'repeatListeners' : 'repeatListenersTimeseries';
        const filename = CSV.CSVHeader(`audience_repeat_listeners`, 'listeners', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.audience[datasetKey].table, {filename}, 'audience_repeat_listeners');
    }

    renderToolbar(){
        const options = [
            {icon: barSvg, value: 'bar', label: "Bar Chart"},
            {icon: lineSvg, value: 'line', label: "Scatter Chart"},
        ];

         let toolbar = [];
         toolbar.push(<div key="wrapper" className="ibox-action-holder">
         <div className="ibox-icon-holder mode-select">
             <ModeSelect options={options} selected={this.state.view} onChange={this.setChartMode} isSearchable={ false } />
         </div>

         <div className="ibox-actions">
           <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
         </div>
         </div>)
         return toolbar;
    }

    renderLegend(dataset){
        let labels = [],
            colors = [];
        if(!dataset || !dataset.length)
            return null;
        
        for(let index = 3; index>=0; index--) {
            for(let datasetIndex = 0; datasetIndex<4; datasetIndex++) {
                const row = dataset[datasetIndex];
                if(row.data[index]) {
                    labels.push(row.labels[index]);
                    colors.push(row.backgroundColor[index]);
                }
            }
        }
        return <ChartLegend labels={labels} colors={colors} />
    }

    setChartView(view){
        this.setState({view});
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    renderChartActions(){
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entityFilterMode="array" />        
        </React.Fragment>
    }    
    
    renderBar(){
        const { audience } = this.props;
        const chartAxesLabels = ['11+', '6-10', '2-5', '1'];
        const options = {
            maintainAspectRatio: false,
            indexAxis: 'y',
            layout: {
                padding: {
                    right: 40
                }
            },
            scales: {
                x: {
                    //barThickness: 20,
                    stacked: true,
                    "ticks": {
                        callback: function formatAxesLabel(value, index, values){
                            return splitByThousands(value)
                        }
                    },
                    title: {
                        display: true,
                        text: '# of Listeners'
                    }
                },
                y: {
                    //barThickness: 20,
                    stacked: true,
                    "ticks": {
                        callback: function formatAxesLabel(value, index, values){
                            return chartAxesLabels[index]
                        }
                    },
                    title: {
                        display: true,
                        text: '# of Listens'
                    }
                }
            },    
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        label: function(context) {
                            const { dataset, dataIndex: index } = context;
                            if(!dataset.labels)
                                return null;
                            
                            return (dataset.labels[index] && dataset.data[index]) ? dataset.labels[index] + ': ' + splitByThousands(dataset.data[index]) : null;
                        }
                    }
                }
            }
        };
        
        let dataset = [];
        if(audience.repeatListeners && audience.repeatListeners.dataset) {
            dataset = audience.repeatListeners.dataset;
            dataset.barThickness = 20;
        }
            
        
        return <Box title="Repeat Listeners" chartActions={this.renderChartActions()} className="" spinnerEnabled={audience.repeatListenersLoading} toolbar={this.renderToolbar()} >
                <div className="row">
                    <div className={`col-xs-12`}>
                        {audience.repeatListeners && <div className="repeat-listeners-chart">
                            <BarChart shadowChart={true} shadowChartWidth={800} shadowChartHeight={300} id="audience_repeat_listeners_line_chart" data={{labels: ['1', '2 - 5', '6 - 10', '11+'].reverse(), datasets: dataset}} options={options} />
                        </div>}
                        {/*this.renderLegend(dataset)*/}
                    </div>
                </div>
            </Box>
    }

    swapAxes(data) {
        if (data) {
            return {
                ...data,
                datasets: data.datasets.map(dataset => ({
                    ...dataset,
                    data: dataset.data.map(point => ({
                        ...point,
                        x: point.y,
                        y: point.x
                    }))
                }))
            };
        }
    }
    
    renderLine() {
        const { audience } = this.props;
        //
        let logStart = 10;
        let logArray = [logStart];
        for(let i = 1; i < 10; i++) {
            logStart *= 10;
            logArray.push(logStart);
        }
        //
        
        const options = {  
            indexAxis: 'y', 
            scales: {
              x: {
                // type: 'logarithmic',
                ticks: {
                    // callback: function(value) {if(logArray.includes(value)) return value}
                    callback: function(value) {if (value % 1 === 0) {return `Week #${value}`;}},
                },
              },
              y: {
                type: 'logarithmic',
                ticks: {
                    // callback: function(value) {if (value % 1 === 0) {return `Week #${value}`;}},
                    callback: function(value) {if(logArray.includes(value)) return value},
                    maxRotation: 90,
                    minRotation: 90
                },
              }
            }, 
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        filter: (item, data) => {
                            console.log(item.text);
                            return !(item.text.includes('('));
                        }
                    }

                },
                tooltip: {
                    // mode: 'index',
                    //position: 'nearest',
                    callbacks: {
                        label: function(context) {
                            const { raw } = context;
                            return `${raw.y} listens - ${raw.listeners} ${raw.engagement} listener${raw.listeners > 1 ? 's':''}`
                        }
                    }
                }
            }
    };

        const originalData = audience.repeatListenersTimeseries;
        const swappedData = this.swapAxes(originalData);

        return <Box title="Repeat Listeners" chartActions={this.renderChartActions()} className="" spinnerEnabled={audience.repeatListenersTimeseriesLoading} toolbar={this.renderToolbar()} >
            <div className="row">
                <div className={`col-xs-12`}>
                    {audience.repeatListenersTimeseries && <div className="chart-block">
                        <ScatterChart shadowChart={true} shadowChartWidth={800} shadowChartHeight={300} id="audience_repeat_listeners_line_chart" data={swappedData} options={options} />

                    </div>}
                </div>
            </div>
        </Box>

    }
    
    render() {
        const { view } = this.state;
        return view == 'bar' ? this.renderBar() : this.renderLine();
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getRepeatListeners: (id, entity, entityFilter) => {
            return dispatch(audienceActions.getRepeatListeners(id, entity, entityFilter ? entityFilter : ownProps.filtered))
        },
        getRepeatListenersTimeseries: (id, entity, entityFilter) => {
            return dispatch(audienceActions.getRepeatListenersTimeseries(id, entity, entityFilter ? entityFilter : ownProps.filtered))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RepeatListeners)
