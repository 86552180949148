import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { find, maxBy, isEqual, cloneDeep } from 'lodash';

import config from '../../../config/config';
import Box from '../../common/Box';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';


class CatalogPieChart extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
		  mode: props.defaultMode || "tracks",
		};
	}

	setChartMode(mode){
		this.setState({mode}, ()=>{
		});

		const { modeChange } = this.props;
		if(typeof modeChange == 'function')
			modeChange(mode);
	}
	
	getValue(stats, key) {
		const value = find(stats, item=>item.id == key);
		return value ? value.value : 0;
	}
	
	
	getData(stats){
		const { mode } = this.state;
		let dataset = [this.getValue(stats, `monetized_${mode}`), this.getValue(stats, `demonetized_${mode}`)];
		return { labels: ['Monetized', 'Demonetized'], datasets: [{data: dataset}]};
	}


	
	render (){
		const { stats } = this.props;
		
		return <div className="chart-block">
			<DoughnutChart shadowChart={true} shadowChartWidth={800} shadowChartHeight={300} data={this.getData(stats)} id={'spotify_monetization_metrics'} />                          
		</div>
	}
}

export default CatalogPieChart;