export const spotifyMonetizationConstants = {
	GET_SPOTIFY_MONETIZATION_REQUEST: 'GET_SPOTIFY_MONETIZATION_REQUEST',
	GET_SPOTIFY_MONETIZATION_SUCCESS: 'GET_SPOTIFY_MONETIZATION_SUCCESS',
	GET_SPOTIFY_MONETIZATION_FAILURE: 'GET_SPOTIFY_MONETIZATION_FAILURE',
	GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST: 'GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST',
	GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS: 'GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS',
	GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE: 'GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE',
	GET_SPOTIFY_MONETIZATION_UPDATE_REQUEST: 'GET_SPOTIFY_MONETIZATION_UPDATE_REQUEST',
	GET_SPOTIFY_MONETIZATION_UPDATE_SUCCESS: 'GET_SPOTIFY_MONETIZATION_UPDATE_SUCCESS',
	GET_SPOTIFY_MONETIZATION_UPDATE_FAILURE: 'GET_SPOTIFY_MONETIZATION_UPDATE_FAILURE',
}