import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CopyToClipboard from '../../common/CopyToClipboard';
import EntityCard from '../../common/EntityCard';

export const spotifyMonetizationSettings = {
	settingsForTable
};

function settingsForTable(){

	let columns = [
	   	{
			id: 'logo',
			className: 'logo-sell ',
			headerClassName: 'logo-sell  no-sort',
			Header: 'Logo',
			accessor: 'image',
			width: 60,
			sortable: false,
			required: true,
			Cell: props => {
				return <div className="logo-container">
					<Link to={`/tracks/${props.original.id}`}>
						<div className="logo" style={{backgroundImage: `url(${props.original.image})`}} />
					</Link>
				</div>
			}
		},
		{
			id: "artist_name",
			className: "imprint-name ",
			headerClassName: "imprint-name  no-sort",
			Header: "Track",
			accessor: "artist",
			sortable: false,
			required: true,
			width: 260,
			Cell: props => {
				const { id, artist, title, isrc } = props.original;
				return <React.Fragment>
					<span>{props.original.id ? <Link to={`/tracks/${props.original.id}`}>{title}</Link> : title}</span>                
					<span>{artist}</span>
					<span className="isrc-holder with-copy">
					{id ? <CopyToClipboard value={isrc}>{isrc}</CopyToClipboard> : <span>{isrc}</span>}</span>
				</React.Fragment>
			}
	    },  
		{
		   id: 'end_month_status',
		   Header: 'Status',
		   accessor: 'lastMonthStatus'
		},                   		
		{
		   id: 'release_type',
		   Header: 'New Release',
		   accessor: 'release_type',
		   Cell: props => <span>{props.value == 'new_release' ? '✓' : '✗'}</span>
		},                   		

		{
			id: 'end_month_streams',
			Header: 'Latest Month Streams',
			accessor: 'lastMonthStreams',
		},
		{
			id: 'end_month_listeners',
			Header: 'Latest Month Listeners',
			accessor: 'lastMonthListeners',
		},
		{
			id: 'total_streams',
			Header: 'Date Range Streams',
			accessor: 'totalStreams',
		},
		{
			id: 'monetized_streams',
			Header: 'Date Range Monetized Streams',
			accessor: 'totalMonetized',
		},
		{
			id: 'demonetized_streams',
			Header: 'Date Range Demonetized Streams',
			accessor: 'totalDemonetized',
		},

		{
			id: 'last_demonetized_month',
			Header: 'Last Demonetized Since',
			accessor: 'demonetizedSince',
		},
		{
			id: 'times_demonetized',
			Header: '# of Months Demonetized',
			accessor: 'monthsDemonetized',
		}
	];
					
	return columns; 
}
