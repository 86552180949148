import { spotifyMonetizationConstants } from '../constants/spotify_monetization';
import { spotifyMonetizationService } from '../services/spotify_monetization';
import { tracksService } from '../services/tracks';
import { spotifyMonetizationFormatter } from '../formatters/spotify_monetization';

export const spotifyMonetizationActions = {
	getSpotifyMonetization,
	getSpotifyMonetizationTotals,
	updateSpotifyMonetization
};

function getSpotifyMonetization(params) {
	return (dispatch, getState) => {
		dispatch(request());
		return spotifyMonetizationService.getSpotifyMonetization(params)
			.then(
				spotifyMonetization => {
					let data = spotifyMonetizationFormatter.formatSpotifyMonetizationFlat(spotifyMonetization);
					//const ids = data.data.map(item=>item.id).slice(0, 20);
					//return tracksService.getMetadata(ids)
					return Promise.resolve()
					.then(metadata => {
						//const tracks = spotifyMonetizationFormatter.formatMetadata(data, metadata.data);
						const tracks = data;
						dispatch(success(tracks));	
						return tracks;
					}) 

					
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_REQUEST } }
	function success(data) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_SUCCESS, data } }
	function failure(error) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_FAILURE, error } }
}

function getSpotifyMonetizationTotals(params) {
	return (dispatch, getState) => {
		dispatch(request());
		return spotifyMonetizationService.getSpotifyMonetizationTotals(params)
			.then(
				spotifyMonetization => {
					let data = spotifyMonetizationFormatter.formatSpotifyMonetizationTotals(spotifyMonetization);
					dispatch(success(data));	
					return data;
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST } }
	function success(data) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS, data } }
	function failure(error) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE, error } }
}

function updateSpotifyMonetization(params, cb) {
	return dispatch => {
		dispatch(request());

		spotifyMonetizationService.updatespotifyMonetization(params)
			.then(
				spotifyMonetization => {
					dispatch(success('Exceptions have been updated'));
					if (cb && typeof cb === 'function') {
						cb()
					}
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_UPDATE_REQUEST } }
	function success(message) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_UPDATE_SUCCESS, title: '' } }
	function failure(message) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_UPDATE_FAILURE, title: 'update exceptions error' } }
}
