import { some, map, sumBy, uniq, find, cloneDeep } from 'lodash';
import moment from 'moment';
import ImageURL from '../../helpers/ImageURL';

export const spotifyMonetizationFormatter = {
	formatSpotifyMonetization,
	formatSpotifyMonetizationFlat,
	formatSpotifyMonetizationTotals,
	formatMetadata
};



function formatSpotifyMonetization(data){
	const dates = uniq(map(data, 'current_end_date')).sort();
	const tracks = uniq(map(data, 'track_id'));
	let rows = [];
	let timeseries = {};
	let grandTotalStreams = 0, grandTotalMonetized = 0, grandTotalDemonetized = 0;
	for(let date of dates) {
		timeseries[date] = {monetized: 0, demonetized: 0};
	}
	
	for(let track of tracks) {
		let lastMonthStreams = 0, lastMonthListeners = 0, lastMonthStatus = '',
			totalStreams = 0, totalMonetized = 0, totalDemonetized = 0,
			monthsDemonetized = 0, demonetizedSince = '';
		
		for(let date of dates) {
			let row = find(data, item=>(item.current_end_date == date && item.track_id == track));
			if(row) {
				lastMonthStreams = row.current_months_plays;
				lastMonthListeners = row.current_months_unique_users;
				lastMonthStatus = row.status;
				grandTotalStreams += row.current_months_plays;
				
				totalStreams += row.current_months_plays;
				if(row.status.endsWith('Demonetized')) {
					totalDemonetized += row.current_months_plays;
					grandTotalDemonetized += row.current_months_plays;
					timeseries[date].demonetized+=row.current_months_plays;
					monthsDemonetized++;
					if(!demonetizedSince)
						demonetizedSince = row.current_end_date;
				}
				else {
					totalMonetized += row.current_months_plays;
					grandTotalMonetized += row.current_months_plays;
					timeseries[date].monetized+=row.current_months_plays;
					demonetizedSince = '';
				}
			}
		}
		rows.push({id: track, lastMonthStreams, lastMonthListeners, lastMonthStatus, totalStreams, totalMonetized, totalDemonetized, monthsDemonetized, demonetizedSince})
	};
	const monetizedTracks = rows.filter(row=>!row.lastMonthStatus.endsWith('Demonetized')).length;
	const demonetizedTracks = rows.filter(row=>row.lastMonthStatus.endsWith('Demonetized')).length;
	const metrics = [
		{id: 'total_streams', title: 'Streams', value: grandTotalStreams},
		{id: 'monetized_streams', title: 'Monetized Streams', value: grandTotalMonetized, percentageValue: Math.round(grandTotalMonetized / grandTotalStreams * 100), valueClass: 'up'},
		{id: 'demonetized_streams', title: 'Demonetized Streams', value: grandTotalDemonetized, percentageValue: Math.round(grandTotalDemonetized / grandTotalStreams * 100), valueClass: 'down'},
		{id: 'monetized_tracks', title: 'Monetized Tracks', value: monetizedTracks, percentageValue: Math.round(monetizedTracks / rows.length * 100), valueClass: 'up'},
		{id: 'demonetized_tracks', title: 'Demonetized Tracks', value: demonetizedTracks, percentageValue: Math.round(demonetizedTracks / rows.length * 100), valueClass: 'down'}
	];
	
	let monetizedDataset = [], demonetizedDataset = [];
	for(let date of dates) {
		let column = timeseries[date];
		monetizedDataset.push(column.monetized);
		demonetizedDataset.push(column.demonetized);
	};
	timeseries = {
		labels: dates,
		datasets: [
			{label: "Monetized", data: monetizedDataset},
			{label: "Demonetized", data: demonetizedDataset},
		]
	}

	return {data: rows, metrics, timeseries};
}

function formatSpotifyMonetizationFlat(data) {
	const newReleaseThreshold = 28;
	const today = moment();
	let total = 0;

	let result = data.map(row=>{
		total = row.total_tracks;
		const artist = ( Array.isArray(row.track_artists) && row.track_artists.length > 0 ) ? row.track_artists[0] : {};
		return {id: row.track_id, 
			lastMonthStreams: row.end_month_streams, 
			lastMonthListeners: row.end_month_listeners , 
			lastMonthStatus: row.end_month_status, 
			totalStreams: row.total_streams, 
			totalMonetized: row.monetized_streams, 
			totalDemonetized: row.demonetized_streams, 
			monthsDemonetized: row.times_demonetized, 
			demonetizedSince: row.last_demonetized_month,
			release_type: row.release,
			
			title: row.track_title,
			isrc: row.isrc,
			artist: artist.name || 'N/A',
			release_date: row.first_released,
			image: ImageURL(row.product_image, row.product_id, 'tracks'),
			//new_release: today.diff(moment(row.first_released), 'days') <= newReleaseThreshold,
			
		};
	});
	return {data: result, total};
}

function formatMetadata(data, metadata) {
	const newReleaseThreshold = 28;
	const today = moment();
	const tracks = cloneDeep(data);
	let rows = [];
	for(let track of tracks.data) {
		let trackMeta = find(metadata, meta=>meta.id == track.id);
		if(trackMeta) {
			track.title = trackMeta.title;
			track.isrc = trackMeta.isrc;
			track.release_date = trackMeta.release_date;
			track.artist = trackMeta.primary_artist.name;
			track.image = ImageURL(trackMeta.product_image, trackMeta.product_id, 'tracks');
			track.new_release = today.diff(moment(track.release_date), 'days') <= newReleaseThreshold;
		}
		rows.push(track);
	}
	tracks.data = rows;
	return tracks;
}

function formatSpotifyMonetizationTotals(data) {
	
	
	const grandTotalMonetized = sumBy(data, 'monetized_streams');
	const grandTotalDemonetized = sumBy(data, 'demonetized_streams');
	const monetizedTracks = sumBy(data, 'monetized_tracks');
	const demonetizedTracks = sumBy(data, 'demonetized_tracks');
	
	const grandTotalStreams = grandTotalMonetized + grandTotalDemonetized;
	const totalTracks = monetizedTracks + demonetizedTracks;
	
	const metrics = [
		{id: 'total_streams', title: 'Streams', value: grandTotalStreams},
		{id: 'monetized_streams', title: 'Monetized Streams', value: grandTotalMonetized, percentageValue: grandTotalStreams ? Math.round(grandTotalMonetized / grandTotalStreams * 100) : 0, valueClass: 'up'},
		{id: 'demonetized_streams', title: 'Demonetized Streams', value: grandTotalDemonetized, percentageValue: grandTotalStreams ? Math.round(grandTotalDemonetized / grandTotalStreams * 100) : 0, valueClass: 'down'},
		{id: 'monetized_tracks', title: 'Monetized Tracks', value: monetizedTracks, percentageValue: totalTracks ? Math.round(monetizedTracks / totalTracks * 100) : 0, valueClass: 'up'},
		{id: 'demonetized_tracks', title: 'Demonetized Tracks', value: demonetizedTracks, percentageValue: totalTracks ? Math.round(demonetizedTracks / totalTracks * 100) : 0, valueClass: 'down'}
	];
	
	const dates = uniq(map(data, 'current_end_date')).sort();
	let monetizedDataset = [], demonetizedDataset = [];
	for(let date of dates) {
		let column = find(data, row=>row.current_end_date == date);
		monetizedDataset.push(column.monetized_streams);
		demonetizedDataset.push(column.demonetized_streams);
	};
	const timeseries = {
		labels: dates,
		datasets: [
			{label: "Monetized", data: monetizedDataset},
			{label: "Demonetized", data: demonetizedDataset},
		]
	}
	
	return {metrics, timeseries};
}