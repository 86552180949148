import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import store from '../../../helpers/store'
import {
  uniq,
  map,
  merge,
  find,
  flatten,
  findKey,
  isEqual,
  get,
  cloneDeep
} from 'lodash';
import {
  Dropdown
} from 'react-bootstrap';
import {
  connect
} from 'react-redux';

// import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import ReactTooltip from 'react-tooltip';
import Datepicker from '../../common/Datepicker';
import Multiselect from '../../common/Multiselect/Multiselect';
//import MultiselectWithSearch from '../../common/MultiselectWithSearch';
import Accordion from './Accordion';
import ReactSelect from '../../common/ReactSelect';
import FilterButton from './FilterButton';

import Spinner from '../../common/Spinner';
import ClickOutsideHolder from '../../../helpers/ClickOutsideHolder'
import MatchMediaWrapper from '../../../helpers/MatchMediaWrapper';
import Tooltip from '../../common/Tooltip';
import ConfirmClick from '../../common/ConfirmClick';
import Modal from '../../common/Modal';

import SearchWidget from '../../pages/home/SearchWidget';
import PlaylistSearchWidget from '../../pages/playlists/Filter/Search';
import EntityFilter from '../../widgets/entityFilter/StandaloneFilter';
import PlaylistFilter from '../../pages/playlists/Filter';

import statusesValues from './enums/statuses';
import releaseDatesValues from './enums/releaseDates.json';
import monthsValues from './enums/months.json';

var tagSvg = require('!svg-inline-loader!../../../../public/img/tag.svg');
var searchSvg = require('!svg-inline-loader!../../../../public/img/search.svg');

const accordionSettings = [
  {
    id: "entities", title: "Entity", description: "Search imprints, artists, products, tracks..."
  },
  {
    id: "status", title: "Status", description: "Filter by Monetized and Demonetized Catalog"
  },
  {
    id: "releaseDate", title: "Release Date", description: "Filter by New Releases, Catalog or All"
  },
  {
    id: "monthsDemonetized", title: "Months Demonetized", description: "Filter by catalog demonetized for a specific number of months"
  }
];

const defaultFilterState = {
  filtered: {
  },
  releaseDate: '',
  status: '',
  display: [],
  months: 0, 
}

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultFilterState,
      accordion: {
        entities: false,
        status: false,
        releaseDate: false,
        monthsDemonetized: false
      }, 
      search: "",
      matchingIds: ["entities", "status", "releaseDate", "monthsDemonetized"],
      currentPlaylistFilterID: null
    }

    this.setSearch = this.setSearch.bind(this);
  }
      

  componentWillMount(){
      
  }
    

  toggleAccordion = (selectedKey) => {
    let { accordion } = this.state;
    for (let key in accordion) {
        accordion[key] = (key == selectedKey) ? !accordion[key] : false;
    }
    this.setState({
        accordion
      })
  }
  
  filterChange = (newFiltered, newDisplay) => {
    let { filtered, display } = this.state;
    const allEntityTypes = uniq([...Object.keys(filtered), ...Object.keys(newFiltered)]);
    for(let type of allEntityTypes) {
      const existingIDs = get(filtered, type, []);
      const newIDs = get(newFiltered, type, []);
      filtered[type] = uniq([...existingIDs, ...newIDs]);
    }    
    display = [...display, ...newDisplay].filter((v,i,a)=>a.findIndex(v2=>['id', 'type'].every(k=>v2[k] ===v[k]))===i);
    this.setState({
      filtered,
      display
    })
  }
  
  filterOptionChange = (value, key) => {
    this.setState({
      [key]: value
    }, ()=>{
    })
  }
  
  removeFromFilter = (deleteEntity) => {
    let { filtered, display } = this.state;
    filtered = {
      ...filtered,
      [deleteEntity.type]: filtered[deleteEntity.type].filter(id=>id != deleteEntity.id)
    };
    display = display.filter(entity=>!(entity.type == deleteEntity.type && entity.id == deleteEntity.id));
    this.setState({
      filtered,
      display
    });    
  }
  
  filterReset = () => {
    this.setState({
      ...this.state,
      ...defaultFilterState,
      filtered: {},
      display: []
    })
  }
  
  removeOption = (option, apply = true) => {
    let newState = {};
    console.log(option);
    let { display, filtered } = this.state;
    switch (option) {
        case 'catalog':
          display = display.filter(entity => entity.type == 'playlists');
          filtered = Object.keys(filtered).filter(key => key == 'playlists').reduce((obj, key) => {return {...obj, [key]: filtered[key]};}, {});
          newState.display = display;
          newState.filtered = filtered;
        break;
        case 'status':
          newState.status = '';
        break;
        case 'releaseDate':
          newState.releaseDate = '';
        break;
        case 'months':
          newState.months = 0;
        break;  
    }
    this.setState({
      ...this.state,
      ...newState
    }, ()=>{
      if(apply)
        this.filterApply();
    })
  }
  
  addToFilter = (entity) => {
    const flattened = {[entity.entity]: [String(entity.id)]};
    const display = [{
      id: String(entity.id),
      title: entity.name_raw,
      type: entity.entity
    }];
    this.filterChange(flattened, display);
  }
  

    componentDidMount() {
      //load saved playlist?
      //load saved entities?
    }
    
    addAllToFilter = (filter) => {
      let flattened = {}, display = [];
      for(let entityType of Object.keys(filter)) {
        if(Array.isArray(filter[entityType]) && filter[entityType].length > 0) {
          for(let entity of filter[entityType]) {
            if(!flattened.hasOwnProperty(entityType))
              flattened[entityType] = [];
            flattened[entityType].push(String(entity.id));
            display.push({
              id: String(entity.id),
              title: entity.name_raw,
              type: entityType
            });
          }

        }
          
      }
      this.filterChange(flattened, display);
    }

    setPlaylists = (filter) => {
      if(filter.value === null) {
        this.props.history.push('/playlists/filter');
        return;
      }
      
      let flattened = {}, display = [];
      for(let entity of filter.value){
        const { entity_type, entity_id } = entity;
        if(!flattened.hasOwnProperty(entity_type))
          flattened[entity_type] = [];
        flattened[entity_type].push(String(entity_id));
        display.push({
          id: String(entity.entity_id),
          title: entity.entity_title,
          type: entity.entity_type
        });
      }
      this.filterChange(flattened, display);
    }
    
    renderDisplay = (entities, hasIcon) => {
      const extraClass = hasIcon ? "with-icon" : "";
      return <div className="tag-list">
          <div className="page-filter-item">
              <div className="page-filter-list">
                  {entities.map(entity=><div key={entity.id} className={`tag ${entity.type}`}>
                      <div className={`tag-name-holder ${extraClass}`}>
                        { hasIcon ? <span className="playlist-tag-icon" dangerouslySetInnerHTML={{__html: tagSvg}}></span> : null }
                          <div className="tag-name">
                              <span>{entity.title}</span>
                          </div>
                          <a
                              className="close-link"
                              onClick={()=>this.removeFromFilter(entity)}
                          >
                              &times;
                          </a>
                      </div>
                  </div>)}
              </div>
          </div>
      </div>
    }
    
    filterApply = () => {
      const filterData = {
        ...this.state.filtered, 
        releaseDate: this.state.releaseDate, 
        status: this.state.status,
        months: this.state.months
      }
      this.props.onApply(filterData, this.state.display);
    }
    
    getAccordionHeader(entity) {
      const accordionItem = accordionSettings.find(item => item.id === entity);

      if (!accordionItem) {
        return null;
      }

      return <div className="playlist-filter-accordion-label">
        <span className="playlist-filter-result-icon" dangerouslySetInnerHTML={{__html: tagSvg}}></span>
        <div className="playlist-filter-accordion-label-text">
          <span className="playlist-filter-accordion-label-title">{accordionItem.title}</span>
          <span className="playlist-filter-accordion-label-placeholder">{accordionItem.description}</span>
        </div>
      </div>
    }

    setSearch(e) {
      const value = e.target.value;
      
      this.setState({
        search: value,
      });
      
      this.getMatchingIds(value);
    }
    
    renderEditLink = (playlistDisplay) => {
      let selectedFilterID = null;
      const { filtered } = this.state;
      const { playlistFilters } = this.props.userEntityFilters;
      
      
      if(filtered && filtered.playlists) {
        const ids = filtered.playlists.sort();
        for(let playlist of playlistFilters) {
          if(isEqual(playlist.entityIDs, ids))
            selectedFilterID = playlist.id;
        }  
      }
      return  selectedFilterID ? <Link className="new-filter-link default-btn default-btn--small default-btn--light" to={{pathname:`/playlists/filter/${selectedFilterID}`}}>Edit Filter</Link> : <Link className="new-filter-link default-btn default-btn--small" to={{pathname:"/playlists/filter/new", state:{defaultEntities: playlistDisplay}}}>Save Filter</Link>
    }
    
    getMatchingIds(value) {
      const matchingIds = [];
      
      accordionSettings.forEach(setting => {
        if (setting.title.match(new RegExp(value, 'i')) || setting.description.match(new RegExp(value, 'i'))) {
          matchingIds.push(setting.id);
        }
      });

      if (value.length > 0 && value.length < 3) {
        return;
      }

      this.setState({
        matchingIds
      })
    }

    render() {
      const { expanded } = this.props;
      if(!expanded)
        return null;
        
      const { display, matchingIds } = this.state;
      const entityDisplay = display.filter(entity=>entity.type != 'playlists');
      const playlistDisplay = display.filter(entity=>entity.type == 'playlists');
      
        return <div className={`filter-wrapper filter-opened playlist-filter`}>
          <div className="filter-summary-holder playlist-filter-summary-holder">            
            <div className="filterSpinner">
              <Spinner enabled={this.props.loading} />
            </div>
            <form role="form" className="form-inline">
                <div className="filter-wrapper-inner">
                    <div className='search-widget'>
                    <form role="form" className="form-inline">
                      <div className="form-group search">
                        <input
                          className={`form-control main-search-input`}
                          placeholder={
                            "Search Filters"
                          }
                          type="text"
                          value={this.state.search}
                          onChange={this.setSearch}
                          />
                      </div>
                    </form>
                    </div>
                    <div className="advanced-filter">
                      <div className="advanced-filter-inner">
                        <Accordion className="chosen-group filter-option-group" id="entities" label={this.getAccordionHeader("entities")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <SearchWidget searchResult={this.addToFilter} placeholder="Search Catalog..." mode="playlist" searchItemMode="single" searchDropdownMode="single" noSearchBtn={true} batch={true} currentlySelectedItems={[]} addAllResults={this.addAllToFilter} />
                          <EntityFilter onChange={this.setPlaylists} filtered={[]} />
                          {this.renderDisplay(entityDisplay, false)}
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="releaseDate" label={this.getAccordionHeader("releaseDate")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <Multiselect elements={releaseDatesValues} selected={this.state.releaseDate} handleChange={(value)=>this.filterOptionChange(value, 'releaseDate')} />
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="status" label={this.getAccordionHeader("status")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <Multiselect elements={statusesValues} selected={this.state.status} handleChange={(value)=>this.filterOptionChange(value, 'status')} />
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="monthsDemonetized" label={this.getAccordionHeader("monthsDemonetized")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          {/* <label className="limit-label with-padding">Months Demonetized</label> */}
                          <div className="slider-container filter-slider-container no-tooltip">
                            <Slider marks={monthsValues} value={this.state.months} onChange={(value)=>this.filterOptionChange(value, 'months')} min={0} step={1} max={12} className="filter-slider"  />
                          </div>

                        </Accordion>
                      </div>
                    </div>
                    <ul className="saved-filters">
                      <div className="form-group button-container">
                        <button className="apply-btn" alt="apply" type="button" onClick={this.filterApply}>Apply Filter</button>
                      </div>
                      <div className="form-group reset-link-container">
                          <a className="reset-filter-link" onClick={(e)=>this.filterReset(e)}>Reset to default</a>
                      </div>
                    </ul>
                </div>
              </form>
          </div>
            
        </div>
    }
}

function mapDispatchToProps(dispatch){
  return {
    getEntityFilters: () => dispatch(userEntityFiltersActions.getUserEntityFilters()),
  }
      
}

function mapStateToProps(state) {
  return {
      filter: state.filter,
      userEntityFilters: state.userEntityFilters
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Filter)