import React from 'react';
import { Chart, Scatter } from 'react-chartjs-2';
import { map, merge } from 'lodash';
import { colors, scatter } from '../ChartDefaults';
import RectangularDraw from './RectangularDraw';
import moment from 'moment';

class ScatterChart extends React.Component {

	constructor(props){
		const options = merge({}, scatter, props.options);
		super(props);
		this.state = {
			options
		};
	}
	
	render() {  
		let { data, options, id, shadowChart = false, shadowChartClass = 'chart-export-shadow', shadowChartWidth = 400, shadowChartHeight = 300, shadowChartOptions, ...rest } = this.props;
		shadowChartOptions = merge({}, this.state.options, shadowChartOptions);
		return (data ? <React.Fragment>
			<Scatter data={data} options={this.state.options} {...rest}></Scatter>
			{shadowChart && <div className={shadowChartClass}><Scatter width={shadowChartWidth} height={shadowChartHeight} id={id} data={data} options={shadowChartOptions} {...rest}></Scatter></div>}
		</React.Fragment>: null);
	}
}

export default ScatterChart