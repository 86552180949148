import { spotifyMonetizationConstants } from '../constants/spotify_monetization';

export function spotifyMonetization(state = {}, action) {
	switch (action.type) {
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_REQUEST:
			return {
				...state,
				loading: true,
				data: []
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data,
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
	
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST:
			return {
				...state,
				loadingTotals: true
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS:
			return {
				...state,
				loadingTotals: false,
				totals: action.data
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE:
			return {
				...state,
				loadingTotals: false,
				error: action.error
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_UPDATE_REQUEST:
			return {
				...state,
				loading: true,
				exceptions: null
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
				exceptions: action.exceptions,
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_UPDATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};

		default:
			return state
	}
} 